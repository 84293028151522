import { Select } from 'antd';
import React, { Component } from 'react';
import { Row, Modal, Carousel } from 'react-bootstrap';

import TopMenu from './TopMenu';

const { Option } = Select;

export default class EnterprisesPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			filter: {
				states: [],
				districts: [],
				phase: ""
			},
			districts: null,
			showFilters: false,
			enterprisesCreated: [],
			selectedEnterprise: null,
			showSelectedEnterpriseModal: false,
			states: process.env.REACT_APP_FOCUS_STATES.split(',')
		}
	}

	componentDidMount() {
		this.getSwavalambis();
	}

	getSwavalambis = (query = '') => {
		fetch(process.env.REACT_APP_API_URL + '/successful-enterprises' + query, {
			method: 'GET',
		}).then(data => data.json())
		.then(data => {
			console.log(data)
			if(data.status) {
				this.setState({ enterprisesCreated: data.enterprisesCreated });
			}
		});
	}

	onChange = (type, e) => {
		let value = e, filter = this.state.filter;
		if(e.target) value = e.target.value;
		if(type === 'states') {
			if(value.length) {
				if(value.indexOf('') > -1) {
					filter[type] = [];
					filter['districts'] = [];
					this.setState({ filter, districts: [] }, () => this.submitFilter());
				} else {
					let statesString = value.join(',');
					fetch(process.env.REACT_APP_API_URL + '/api/v1/location/districts?states=' + statesString, {
						method: 'GET',
					}).then(data => data.json())
					.then(data => {
						if(data.status) {
							filter['states'] = value;
							filter['districts'] = [];
							this.setState({ districts: data.districts, filter }, () => this.submitFilter());
						}
					});
				}
			} else {
				filter[type] = [];
				filter['districts'] = [];
				this.setState({ filter, districts: [] }, () => this.submitFilter());
			}
		} else {
			filter[type] = value;
			this.setState({ filter }, () => this.submitFilter());
		}
	}

	submitFilter = () => {
		let query = '', filter = this.state.filter;
		if(filter.states.length) {
			query ? query += '&states=' + filter.states.join(',') : query = '?states=' + filter.states.join(',');
		}

		if(filter.districts.length) {
			query ? query += '&districts=' + filter.districts.join(',') : query = '?districts=' + filter.districts.join(',');
		}

		if(filter.phase) {
			query ? query += '&phase=' + filter.phase : query = '?phase=' + filter.phase;
		}

		this.getSwavalambis(query);
	}

	searchFilter = (input, option) => {
		input = input.toLowerCase();
		if(!Array.isArray(option.props.children)) {
			if(typeof option.props.children === 'string') {
				return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
			}
		}
	}

	selectedEnterprise = (enterprise) => {
		if(enterprise) {
			this.setState({ selectedEnterprise: enterprise, showSelectedEnterpriseModal: true });
		}
	}

	hideEnterpriseModal = () => {
		this.setState({ selectedEnterprise: null, showSelectedEnterpriseModal: false });
	}

	toggleFilters = () => {
		this.setState({ showFilters: !this.state.showFilters });
	}

	render() {
		return (
			<div className="enterprises-page">
				<TopMenu selected="swavalambis" />
				<h4 className="header">MEET THE SWAVALAMBIS</h4>
				<div className="main-container">
					<div className="filter-container">
						<div className="filter-toggle" onClick={this.toggleFilters}>
							<span>Filters</span>
							<i className={this.state.showFilters ? "fas fa-chevron-up" : "fas fa-chevron-up reverse"}></i>
						</div>
						<div className={this.state.showFilters ? "filters shown" : "filters hidden"}>
							<div className="filter">
								<label className="control-label">States:</label>
								<Select mode="multiple" showSearch style={{ width: '100%' }} placeholder="All" onChange={this.onChange.bind(this, 'states')} value={this.state.filter.states} filterOption={(input, option) => this.searchFilter(input, option)}>
									<Option value="">All</Option>
									{this.state.states.map((state, index) => {
										return (
											<Option key={index} value={state}>{state}</Option>
										)
									})}
								</Select>
							</div>
							<div className="filter">
								<label className="control-label">Districts:</label>
								<Select mode="multiple" showSearch style={{ width: '100%' }} placeholder="All" onChange={this.onChange.bind(this, 'districts')} value={this.state.filter.districts} filterOption={(input, option) => this.searchFilter(input, option)} disabled={!this.state.filter.states ? true : false}>
									{this.state.districts ? (
										this.state.districts.map((district, districtIndex) => {
											return (
												<Option key={districtIndex} value={district.name}>{district.name + ' (' + district.state + ')'}</Option>
											)
										})
									) : (null)}
								</Select>
							</div>
							<div className="filter">
								<label className="control-label">Enterprise Stage:</label>
								<Select style={{ width: '100%' }} placeholder="Select Enterprise Stage" onChange={this.onChange.bind(this, 'phase')} value={this.state.filter.phase}>
									<Option value="">All</Option>
									<Option value="Start-up">Start-up</Option>
									<Option value="Scale-up">Scale-up</Option>
								</Select>
							</div>
						</div>
					</div>
					<div className="swavalambis-container">
						{this.state.enterprisesCreated.map((swavalambi, swavalambiIndex) => {
							return (
								<div className="swavalambi-container" key={swavalambiIndex} style={swavalambi.enterpriseSetup.otherImageUrls.length ? {backgroundImage: `url(${swavalambi.enterpriseSetup.otherImageUrls[0]})`} : {backgroundImage: 'url("/images/enterprise-placeholder.png")'}} onClick={this.selectedEnterprise.bind(this, swavalambi)}>
									<div className="title-block">{swavalambi.district}</div>
									<div className="text-container">
										<span className="title">{swavalambi.enterpriseSetup.entityName}</span>
										<span className="entrepreneur">{swavalambi.name.trim()}</span>
										{swavalambi.enterpriseSetup.creationMonth && swavalambi.enterpriseSetup.creationYear ? (
											<span className="creation">Created On: <span>{swavalambi.enterpriseSetup.creationMonth + ', ' + swavalambi.enterpriseSetup.creationYear}</span></span>
										) : (null)}
									</div>
								</div>
							)
						})}
					</div>
				</div>

				{this.state.showSelectedEnterpriseModal && this.state.selectedEnterprise ? (
					<Modal className="enterprise-details-modal" show={this.state.showSelectedEnterpriseModal} size="lg" onHide={this.hideEnterpriseModal}>
						<Modal.Body className="mb20">
							<Row className="enterprise-container">
								<span className="close-container" onClick={this.hideEnterpriseModal}>x</span>
								<div className="enterprise-img-container">
									<Carousel interval={3000} indicators={false}>
										{this.state.selectedEnterprise.enterpriseSetup.otherImageUrls.length ? (
											this.state.selectedEnterprise.enterpriseSetup.otherImageUrls.map((image, index) => {
												return (
													<Carousel.Item>
														<img className="d-block" src={image} alt={"Enterprise Image " + (index + 1)} />
													</Carousel.Item>
												)
											})
										) : (
											<Carousel.Item>
												<img className="d-block" src="/images/enterprise-placeholder.png" alt="Enterprise Placeholder" />
											</Carousel.Item>
										)}
									</Carousel>
								</div>
								<div className="enterprise-detail-container">
									<div className="detail-container">
										<span className="title">Enterprise Name: </span>
										<span className="value capitalize">{this.state.selectedEnterprise.enterpriseSetup.entityName}</span>
									</div>
									<div className="detail-container">
										<span className="title">Entrepreneur Name: </span>
										<span className="value capitalize">{this.state.selectedEnterprise.name}</span>
									</div>
									{this.state.selectedEnterprise.enterpriseSetup.creationMonth && this.state.selectedEnterprise.enterpriseSetup.creationYear ? (
										<div className="detail-container">
											<span className="title">Created On: </span>
											<span className="value capitalize">{this.state.selectedEnterprise.enterpriseSetup.creationMonth + ', ' + this.state.selectedEnterprise.enterpriseSetup.creationYear}</span>
										</div>
									) : (null)}
									<div className="detail-container">
										<span className="title">City: </span>
										<span className="value">{this.state.selectedEnterprise.city}</span>
									</div>
									<div className="detail-container">
										<span className="title">District: </span>
										<span className="value">{this.state.selectedEnterprise.district}</span>
									</div>
									<div className="detail-container">
										<span className="title">State: </span>
										<span className="value">{this.state.selectedEnterprise.state}</span>
									</div>
									{this.state.selectedEnterprise.enterpriseSetup.enterpriseRegistered === 'Yes' ? (
										<div className="detail-container">
											<span className="title">Enterprise Stage: </span>
											<span className="value">{this.state.selectedEnterprise.enterpriseSetup.enterpriseState}</span>
										</div>
									) : (null)}
									{this.state.selectedEnterprise.enterpriseSetup.entrepreneurStory ? (
										<div className="detail-container">
											<span className="title">Entrepreneur Story: </span>
											<span className="value">{this.state.selectedEnterprise.enterpriseSetup.entrepreneurStory}</span>
										</div>
									) : (null)}
								</div>
							</Row>
						</Modal.Body>
					</Modal>
				) : (null)}
				<div className="hero-gradient">
					<div id="wave">
						<svg xmlns="https://www.w3.org/2000/svg" viewBox="0 0 1170 193">
							<path d="M1175 131.2c0 0-81-89.4-224.3-103.4S713 72 665 97c-86 46-148 63-271 7C221.7 25.5 56 104.5-4 197.4 -4 58.7-3.3 0.3-3.3 0.3L1175 0V131.2z"></path>
						</svg>
					</div>
					<img className="le-logo" src="https://assets.letsendorse.com/le/le_logo_white.png" height="80" alt="LetsEndorse" />
				</div>
			</div>
		);
	}
}
