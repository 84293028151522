import { GoogleMap, Marker, withGoogleMap } from "react-google-maps";
import React, { Component } from 'react';
import { Modal, Row, Col, Button } from "react-bootstrap";
import moment from 'moment';
import TopMenu from './TopMenu';
import { Carousel } from 'react-bootstrap';
let iconMarker = new window.google.maps.MarkerImage(
	// "https://lh3.googleusercontent.com/bECXZ2YW3j0yIEBVo92ECVqlnlbX9ldYNGrCe0Kr4VGPq-vJ9Xncwvl16uvosukVXPfV=w300",
	// url('/images/'),
	'/images/SCK-Map-Pointer.png',
	null, /* size is determined at runtime */
	null, /* origin is 0,0 */
	null, /* anchor is bottom center of the scaled image */
	new window.google.maps.Size(32, 32)
);
const PlatformOrgsMap = withGoogleMap((props) =>
	<React.Fragment>
	<GoogleMap defaultZoom={props.defaultZoom} defaultCenter={props.defaultCenter}>
		{props.orgs.map((org, orgIndex) => {
			return (
				<Marker icon={iconMarker} key={orgIndex} position={{ lat: org.location.latitude, lng: org.location.longitude }} title={org.ngoName} onClick={() => props.onMarkerClick(orgIndex)} />
			)
		})}
	</GoogleMap>
	<div style={{position:'fixed', bottom:"0",background: "#ef5a20",padding: "3px",textAlign:"center", margin:"10px"}}><h4 style={{color: "#fff", fontSize:"16px"}}>To approach SIDBI SWAVALAMBAN CONNECT KENDRAs, kindly call on our toll-free number 1800-121-1265 anytime and enter your 6-digit area pin-code.</h4></div></React.Fragment>
);



export default class SCKMap extends Component {
	constructor(props) {
		super(props);
		this.state = {
			//userType: null,
			orgs: [],
			// sizePerPage: 25,
			selectedOrg: null,
			// showTableView: true,
			// newNGO: {
			// 	ngoName: '',
			// 	email: '',
			// 	mobileNumber: '',
			// 	fullName: '',
			// 	logo: '',
			// 	state: '',
			// 	district: '',
			// 	city: '',
			// 	pincode: '',
			// 	address1: '',
			// 	address2: '',
			// 	location: {
			// 		latitude: '',
			// 		longitude: '',
			// 		label: ''
			// 	},
			// 	leProfileUrl: '',
			// 	ngoUrl: ''
			// },
			// newNGOModalVisibility: false,
			// allCities: [],
			// allStates: [],
			// allDistricts: [],
			// errorObj: {},
			defaultCenter: {lat: 20.5937, lng: 78.9629},
			defaultZoom: 5
		}
	}

	componentDidMount() {
		// fetch(process.env.REACT_APP_API_URL + '/api/v1/user/user-type/' + this.props.match.params.type, {
		// 	method: 'GET',
		// 	// headers: {
		// 	// 	'x-auth-token': JSON.parse(readCookie('access_token'))
		// 	// }
		// }).then(data => data.json())
		// .then(data => {
		// 	if(data.status) {
		// 		this.setState({ userType: data.data });
		// 	}
		// });

		this.getOrgs();

		// fetch(process.env.REACT_APP_API_URL + '/api/v1/location/states', {
		// 	method: 'GET',
		// 	// headers: {
		// 	// 	'x-auth-token': JSON.parse(readCookie('access_token'))
		// 	// }
		// }).then(data => data.json())
		// .then(data => {
		// 	if(data.status) {
		// 		this.setState({ allStates: data.data.states });
		// 	}
		// });
	}

	getOrgs = () => {
		fetch(process.env.REACT_APP_API_URL + '/api/v1/ngo/ee', {
			method: 'GET',
			// headers: {
			// 	'x-auth-token': JSON.parse(readCookie('access_token'))
			// }
		}).then(data => data.json())
		.then(data => {
			if(data.status) {
				let defaultCenter = this.state.defaultCenter, defaultZoom = this.state.defaultZoom;
				if(data.ngos.length === 1) {
					defaultZoom = 9;
					defaultCenter = {lat: data.ngos[0].location.latitude, lng: data.ngos[0].location.latitude};
				}
				this.setState({ orgs: data.ngos, defaultCenter, defaultZoom });
			}
		});
	}

	// formatName = (cell, row) => {
	// 	if(cell && row._id) {
	// 		return (
	// 			<a className="org-name" href={'/org-types/' + this.props.match.params.type + '/' + row._id}>{cell}</a>
	// 		)
	// 	} else return null;
	// }

	// formatDate = (cell, row) => {
	// 	if(cell) {
	// 		return moment(cell).format('DD/MM/YYYY hh:mm A')
	// 	} else return null;
	// }

	// formatAddressObj = (cell, row) => {
	// 	if(cell && cell._id && cell.name) {
	// 		return cell.name;
	// 	} else if(cell !== "null" && typeof cell === "string") return cell;
	// 	else if(cell === "null" && typeof cell === "string") return "N/A";
	// 	else return null;
	// }

	// formatLocation = (cell, row) => {
	// 	if(cell && cell.latitude && cell.longitude) {
	// 		return (
	// 			<a id="org-location" href={`https://maps.google.com/?q=${cell.latitude},${cell.longitude}`} target="#">{cell.latitude + ', ' + cell.longitude}</a>
	// 		);
	// 	} else return null;
	// }



	handlePlatformOrgsMapMarkerClick = (index) => {
		this.setState({ selectedOrg: this.state.orgs[index] });
	}



	render() {


		if(this.state.userType !== null) {
			return (
				<div className="org-type-page page-container">
				<TopMenu selected="sck-map" />

				<br/>

						<Col md={12} className="d-flex platform-orgs-map-container">
							<PlatformOrgsMap orgs={this.state.orgs} defaultCenter={this.state.defaultCenter} defaultZoom={this.state.defaultZoom} onMarkerClick={this.handlePlatformOrgsMapMarkerClick} containerElement={<div style={{ height: '90vh', width: '100%' }} />} mapElement={<div style={{ height: '100%', width: '100%' }} />} />
							{this.state.selectedOrg !== null ? (
								<Col md={4} className="platform-orgs-map-details-container">
								<Row>
										<span style={{textAlign:"center",color:"#ef5a20", fontWeight:"1000", textTransform:"uppercase"}}><span className="heading">SIDBI SWAVALAMBAN CONNECT KENDRA - {this.state.selectedOrg.district} </span> </span>
									</Row>
									<Row>
										<span className="heading">SCK Name : </span>
										<span className="value">{this.state.selectedOrg.ngoName}</span>
									</Row>
									<Row>
										<span className="heading">Address : </span>
										<span className="value">
											{this.state.selectedOrg.address1+' '} {this.state.selectedOrg.address2 && this.state.selectedOrg.address2!=='' && (',' + this.state.selectedOrg.address2) }
											</span>
									</Row>
									<Row>
										<span className="heading">State : </span>
										<span className="value">{this.state.selectedOrg.state}</span>
									</Row>
									<Row>
										<span className="heading">District : </span>
										<span className="value">{this.state.selectedOrg.district}</span>
									</Row>
									{/* <Row>
										<span className="heading">Added On : </span>
										<span className="value">{moment(this.state.selectedOrg.addedOn).format('DD/MM/YYYY hh:mm A')}</span>
									</Row> */}
									{/* <Row>
										<span className="heading">Total Members : </span>
										<span className="value">{this.state.selectedOrg.memberCount}</span> */}
									{/* </Row> */}
									{/* <Row> */}
										{/* <span className="heading">Status : </span> */}
										{/* <span className="value">{this.state.selectedOrg.status.toString().toUpperCase()}</span> */}
										{/* {JSON.parse(readCookie('userData')).type !== 'sidbi' ? (
											<span className="selected-org-change-status" onClick={this.changeStatus.bind(this, this.state.selectedOrg._id, this.state.selectedOrg.status)}>Change</span>
										) : (null)} */}
									{/* </Row> */}
									<Row>
										<span className="heading">Location : </span>
										<span className="value">
											<a href={`${this.state.selectedOrg.locationUrl}`} target="_blank">{this.state.selectedOrg.location.label.trim() ? `${this.state.selectedOrg.ngoName.trim()},${this.state.selectedOrg.location.label.trim()}` : `${this.state.selectedOrg.location.latitude},${this.state.selectedOrg.location.longitude}`}</a>
										</span>
									</Row>
									<Row>
										{this.state.selectedOrg.officePhotos && this.state.selectedOrg.officePhotos.length!==0 && <span className="heading">Office Photos : </span>}
										
						
						
					

									</Row>
									<Row>
											{
												this.state.selectedOrg.officePhotos && this.state.selectedOrg.officePhotos.length!==0 && <Carousel style={{width:"500"}}>{this.state.selectedOrg.officePhotos.map(imageUrl=>{
													return <Carousel.Item><img src={imageUrl} height="200" width="400" /></Carousel.Item>
												})  }</Carousel>
											}
										</Row>
									<Row>
										<span className="heading"></span>
										<span className="value"></span>
									</Row>
								</Col>
							) : (null)}
						</Col>
					
				</div>
			);
		} else return null;
	}
}
