import Slider from "react-slick";
import React, { Component } from 'react';
import { Carousel } from 'react-bootstrap';

import TopMenu from './TopMenu';

export default class LandingPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
		}
	}

	componentDidMount() {
	}

	render() {
		var settings = {
			dots: true,
			// speed: 500,
			// infinite: true,
			// autoplay: true,
			// slidesToShow: 3,
			slidesToScroll: 1,
			// autoplaySpeed: 1000,
		};

		return (
			<div className="landing-page">
				<TopMenu selected="home" />
				<div className="banner-container">
					<Carousel interval={5000}>
						<Carousel.Item>
							<div style={{backgroundImage: "url(/images/banner-1.png)" }} alt="First slide">
								<span>स्वावलंबी भारत<br />सशक्त भारत</span>
							</div>
						</Carousel.Item>
						<Carousel.Item>
							<div style={{backgroundImage: "url(/images/banner-4.jpg)", backgroundPosition: "right 25%" }} alt="Forth slide">
								<span>स्वावलंबी भारत<br />सशक्त भारत</span>
							</div>
						</Carousel.Item>
						<Carousel.Item>
							<div style={{backgroundImage: "url(/images/banner-5.jpg)" }} alt="Fifth slide">
								<span>स्वावलंबी भारत<br />सशक्त भारत</span>
							</div>
						</Carousel.Item>
						<Carousel.Item>
							<div style={{backgroundImage: "url(/images/banner-7.jpg)" }} alt="Seventh slide">
								<span>स्वावलंबी भारत<br />सशक्त भारत</span>
							</div>
						</Carousel.Item>
					</Carousel>
				</div>
				<div style={{
					// position: 'fixed',
					bottom: "0", background: "#ef5a20", padding: "3px", textAlign: "center", margin: "10px", paddingTop: '30px',
				}}><h4 style={{ color: "#fff", fontSize: "24px" }}>To approach SIDBI SWAVALAMBAN CONNECT KENDRAs, kindly call on our toll-free number 1800-121-1265 anytime and enter your 6-digit area pin-code.</h4><h5 style={{ color: "#fff", fontSize: "14px" }}>सिडबी स्वावलंबन कनेक्ट केंद्रों से संपर्क करने के लिए, कृपया हमारे टोल-फ्री नंबर 1800-121-1265 पर कॉल करें और अपना 6-अंकीय क्षेत्र पिन-कोड दर्ज करें।</h5></div>
				<div className="dots-section">
					<div className="wrap">
						<img className="visually-hidden" src="/images/dots.png" height="717" alt="" />
						<img className="mobile-only" src="/images/mobile-dots.png" width="100%" alt="" />
						<div className="image-wrap">
							<span data-wow-delay="100ms" className="circle wow zoomIn le-dots" style={{visibility: "visible", animationDelay: "100ms", animationName: "zoomIn"}}></span>
							<span data-wow-delay="200ms" className="circle wow zoomIn sector" style={{visibility: "visible", animationDelay: "200ms", animationName: "zoomIn"}}>Livelihoods</span>
							<span data-wow-delay="200ms" className="circle wow zoomIn institutions" style={{visibility: "visible", animationDelay: "200ms", animationName: "zoomIn"}}>Self-reliance</span>
							<span data-wow-delay="300ms" className="circle wow zoomIn themes" style={{visibility: "visible", animationDelay: "300ms", animationName: "zoomIn"}}>Self-employment</span>
							<span data-wow-delay="300ms" className="circle wow zoomIn people" style={{visibility: "visible", animationDelay: "300ms", animationName: "zoomIn"}}>Entrepreneurship</span>

							<i className="dot_image_02 dot_image clad wow zoomIn" data-wow-delay="587ms" style={{top: "479px", left: "1027px", visibility: "visible", animationDelay: "587ms", animationName: "zoomIn"}}></i>
							<i className="dot_image_03 dot_image clad wow zoomIn" data-wow-delay="302ms" style={{top: "519px", left: "366px", visibility: "visible", animationDelay: "302ms", animationName: "zoomIn"}}></i>
							<i className="dot_image_04 dot_image clad wow zoomIn" data-wow-delay="382ms" style={{top: "10px", left: "876px", visibility: "visible", animationDelay: "382ms", animationName: "zoomIn"}}></i>
							<i className="dot_image_05 dot_image clad wow zoomIn" data-wow-delay="416ms" style={{top: "153px", left: "319px", visibility: "visible", animationDelay: "416ms", animationName: "zoomIn"}}></i>
							<i className="dot_image_06 dot_image clad wow zoomIn" data-wow-delay="311ms" style={{top: "52px", left: "0px", visibility: "visible", animationDelay: "311ms", animationName: "zoomIn"}}></i>
							<i className="dot_image_07 dot_image clad wow zoomIn" data-wow-delay="562ms" style={{top: "32px", left: "195px", visibility: "visible", animationDelay: "562ms", animationName: "zoomIn"}}></i>
							<i className="dot_image_08 dot_image clad wow zoomIn" data-wow-delay="531ms" style={{top: "13px", left: "625px", visibility: "visible", animationDelay: "531ms", animationName: "zoomIn"}}></i>
							<i className="dot_image_10 dot_image clad wow zoomIn" data-wow-delay="307ms" style={{top: "314px", left: "911px", visibility: "visible", animationDelay: "307ms", animationName: "zoomIn"}}></i>
							<i className="dot_image_11 dot_image clad wow zoomIn" data-wow-delay="304ms" style={{top: "16px", left: "1125px", visibility: "visible", animationDelay: "304ms", animationName: "zoomIn"}}></i>
							<img className="main-image" src="/images/dots.png" height="717" alt="" />
						</div>
						<div className="center-wrap">
							<div className="dots">
								<span className="dot"></span>
								<span className="dot"></span>
								<span className="dot"></span>
								<span className="dot"></span>
							</div>
							<h4>MISSION SWAVALAMBAN</h4>
							<p>Boosting self-employment and self-reliance at the base of the pyramid</p>
						</div>
					</div>
				</div>

				<div className="about-mission" id="the-mission">
					<h4>ABOUT MISSION SWAVALAMBAN</h4>

					<p>The seeds of economic self-reliance and self-sufficiency that Khadi movement had sown in the 1920s forms the very inspiration of Mission 10,000 Swavalambis. Simply put, we have made it our mission to pull at least 10,000 families out of the vicious cycle of poverty by fostering self-employment and boosting livelihoods. With support from SIDBI (Small Industries Development Bank of India), we have begun working in 100 districts across 5 states of India (viz. Uttar Pradesh, Bihar, Jharkhand, Odisha and Telangana) to identify, nurture and enable youth, women and men to start and grow their micro/small/medium enterprise from where they are based while serving local communities and boosting local economies. In a way, our attempt is also designed to counter urban migration and make self-employment aspirational and most importantly, sustainable.</p>

					<p>We have deputed a hundred NGO partners of ours to lead the mobilization and awareness creation in each of the focal districts and with our ground teams in place, we are set on a road to make a dent in the economic situation of genuine and committed individuals in villages, Tier 3 and 4 cities.</p>

					<p>Mission 10,000 Swavalambi is our ambitious attempt to build the fortune at the bottom of the pyramid. And we rely heavily on collaboration, technology and sincere hard work to realize the dreams of 10,000 and more small enterprise aspirants. Now how can this big a Mission unfold unless we stand in front of it with open arms! Needless to say, we are open to ideas, thoughts, collaborations or simply, deliberations. Just drop us a line at <a href="mailto:varun@letsendorse.com" target="_blank" rel="noopener noreferrer">varun@letsendorse.com</a>.</p>
				</div>

				<div className="partners-section">
					<h4>PARTNERS IN CHANGE</h4>
					<Slider {...settings}>
						<a href="https://www.sidbi.in/" target="_blank" rel="noopener noreferrer">
							<img src="/images/sidbi-logo.png" alt="SIDBI" />
						</a>
					</Slider>
				</div>

				<div className="hero-gradient">
					<div id="wave">
						<svg xmlns="https://www.w3.org/2000/svg" viewBox="0 0 1170 193">
							<path d="M1175 131.2c0 0-81-89.4-224.3-103.4S713 72 665 97c-86 46-148 63-271 7C221.7 25.5 56 104.5-4 197.4 -4 58.7-3.3 0.3-3.3 0.3L1175 0V131.2z"></path>
						</svg>
					</div>
					<img className="le-logo" src="https://assets.letsendorse.com/le/le_logo_white.png" height="80" alt="LetsEndorse" />
				</div>
			</div>
		)
	}
}
