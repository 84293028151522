import React, { Component } from 'react';

import TopMenu from './TopMenu';

const team = [
	{name: 'Varun Kashyap', type: 'Program Management' },
	{name: 'Monika Shukla', type: 'Program Management' },
	{name: 'Subham Chatterjee', type: 'Program Management' },
	{name: 'Protyusha Mazumder', type: 'Program Management' },
	{name: 'Barsha Saikia', type: 'Program Management' },
	{name: 'Karunesh Shukla', type: 'Uttar Pradesh Task Force'},
	{name: 'Arvind Chaurasia', type: 'Uttar Pradesh Task Force'},
	{name: 'Narendra Verma', type: 'Uttar Pradesh Task Force'},
	{name: 'Arif Rahman', type: 'Uttar Pradesh Task Force'},
	{name: 'Sajid Khan', type: 'Uttar Pradesh Task Force'},
	{name: 'Saurabh Shukla', type: 'Uttar Pradesh Task Force'},
	{name: 'Satyaprakash Dev Pandey', type: 'Uttar Pradesh Task Force'},
]


export default class AboutPage extends Component {
	render() {
		return (
			<div className="about-page">
				<TopMenu selected="about" />
				<h4 className="header">MEET OUR TEAM</h4>
				<div className="main-container">
					<h4>Program Management</h4>
					{team.map((member, index) => {
						if(member.type === 'Program Management') {
							return (
								<div className="member-container">
									<span className="name">{member.name}</span>
								</div>
							)
						} else return null;
					})}

					<h4>Uttar Pradesh Task Force</h4>
					{team.map((member, index) => {
						if(member.type === 'Uttar Pradesh Task Force') {
							return (
								<div className="member-container">
									<span className="name">{member.name}</span>
								</div>
							)
						} else return null;
					})}
				</div>
				<a className="btn visit-button" href="https://www.letsendorse.com/" target="_blank" rel="noopener noreferrer">Visit LetsEndorse</a>
				<div className="hero-gradient">
					<div id="wave">
						<svg xmlns="https://www.w3.org/2000/svg" viewBox="0 0 1170 193">
							<path d="M1175 131.2c0 0-81-89.4-224.3-103.4S713 72 665 97c-86 46-148 63-271 7C221.7 25.5 56 104.5-4 197.4 -4 58.7-3.3 0.3-3.3 0.3L1175 0V131.2z"></path>
						</svg>
					</div>
					<img className="le-logo" src="https://assets.letsendorse.com/le/le_logo_white.png" height="80" alt="LetsEndorse" />
				</div>
			</div>
		);
	}
}
