import React, { Component } from 'react';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import {createBrowserHistory} from 'history';

import LandingPage from './components/LandingPage';
import EnterprisesPage from './components/EnterprisesPage';
import AboutPage from './components/AboutPage';
import SCKMap from './components/SCKMap';
const history = createBrowserHistory();

export default class Routes extends Component {
  render() {
    return (
      <Router history={history}>
        <Switch>
        	<Route exact path="/" component={LandingPage} />
          <Route exact path="/swavalambis" component={EnterprisesPage} />
          <Route exact path="/about" component={AboutPage} />
          <Route exact path="/sck-map" component={SCKMap} />
          <Redirect path="*" to="/" />
        </Switch>
      </Router>
    )
  }
}