import React, { Component } from 'react';
import { Navbar, Nav } from 'react-bootstrap';

export default class TopMenu extends Component {
	render() {
		return (
			<div className="topmenu-container">
				<Navbar bg="light" expand="lg">
				  <Navbar.Brand href="">
				  	<img src="/images/mission-swavalamban-logo.png" height="60" alt="Mission Swavalamban" />
				  </Navbar.Brand>
				  <Navbar.Toggle aria-controls="basic-navbar-nav" />
				  <Navbar.Collapse id="topbar-nav" className="justify-content-end">
				    <Nav className="ml-auto">
				      <Nav.Link href={this.props.selected !== "home" ? "/" : "#the-mission"}>THE MISSION</Nav.Link>
				      <span className="pipe">|</span>
				      <Nav.Link href={this.props.selected !== "swavalambis" ? "/swavalambis" : null}>MEET THE SWAVALAMBIS</Nav.Link>
				      <span className="pipe">|</span>
					  <Nav.Link href={this.props.selected !== "sck-map" ? "/sck-map" : null}>LOCATE SCKs</Nav.Link>
				      <span className="pipe">|</span>
				      {/* <Nav.Link href={this.props.selected !== "about" ? "/about" : null}>ABOUT US</Nav.Link> */}
				      <Nav.Link href="https://www.letsendorse.com/" target="_blank">ABOUT US</Nav.Link>
				    </Nav>
				  </Navbar.Collapse>
				</Navbar>
			</div>
		);
	}
}
